import { setCookie } from 'nookies';
import { USER_EMAIL_COOKIE_NAME, SYSTEM_COOKIE_MAX_AGE } from '@constants';

const setUserEmailCookie = (userEmail: string): void => {
  setCookie(null, USER_EMAIL_COOKIE_NAME, userEmail, {
    maxAge: SYSTEM_COOKIE_MAX_AGE,
    expires: new Date(Date.now() + SYSTEM_COOKIE_MAX_AGE * 1000),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'lax',
  });
};

export default setUserEmailCookie;
