import { classnames } from '@tailwindcss-classnames';

const baseGrid = classnames(
  'grid',
  'gap-x-3',
  'gap-y-10',
  'md:gap-x-5',
  'grid-cols-2',
  'justify-items-center'
);

export const fewItems = classnames(baseGrid, 'items-start', 'justify-center');

const oneColGrid = classnames(fewItems, 'lg:grid-cols-1');
const twoColGrid = classnames(fewItems, 'lg:grid-cols-2');
const threeInFour = classnames(fewItems, 'lg:grid-cols-3');
const threeColGrid = classnames(baseGrid, 'lg:grid-cols-3');
export const centerItem = classnames('mx-auto');

const fourColGrid = classnames(baseGrid, 'lg:grid-cols-3', 'xl:grid-cols-4');

export const getListWrapper = (colNumber: number): string =>
  classnames({
    [threeColGrid]: colNumber === 3,
    [fourColGrid]: colNumber !== 3,
  });

export const fewerItems = {
  1: oneColGrid,
  2: twoColGrid,
  3: threeInFour,
};

const doubleWideStyles = classnames('col-span-2', 'lg:col-span-1', 'w-full');

export const cardSizeWrapper = (useDoubleWide: boolean): string =>
  classnames('lg:max-w-md', 'h-full', 'first:mr-0', 'last:ml-0', {
    [doubleWideStyles]: useDoubleWide,
  });
