import { classnames } from '@tailwindcss-classnames';

const darkBgText = classnames('text-white');
const lightBgTextHeader = classnames('text-nzxt-light-grey-900');
const lightBgTextSubeader = classnames('text-nzxt-light-grey-600');
const lightBgTextLegal = classnames('text-nzxt-light-grey-800');

const defaultSpacing = classnames(
  'lg:ml-8',
  'mt-6',
  'lg:mt-0',
  'sm:w-full',
  'md:max-w-lg',
  'xl:max-w-xl'
);
const simpleSpacing = classnames(
  'mt-6',
  'mx-auto',
  'lg:mx-0',
  'lg:mt-0',
  'sm:w-full',
  'md:max-w-xl',
  'lg:max-w-lg',
  'xl:max-w-xl'
);

export const signUpHeadingWrapper = (isSimple: boolean): string =>
  classnames('relative', 'lg:flex-1', {
    [defaultSpacing]: !isSimple,
    [simpleSpacing]: isSimple,
  });
export const signUpheading = (isInverted: boolean): string =>
  classnames('text-3xl', 'font-extrabold', 'tracking-tight', 'md:text-5xl', {
    [darkBgText]: isInverted,
    [lightBgTextHeader]: !isInverted,
  });

export const cardWrapper = classnames(
  'py-6',
  'md:py-12',
  'xl:py-16',
  'rounded-md',
  'px-4',
  'md:px-0'
);

const simpleDarkSubheading = classnames(
  'text-3xl',
  'font-extrabold',
  'md:text-4xl',
  'text-nzxt-violet-100',
  '2xl:max-w-3xl',
  'max-w-xl'
);

const simpleLightSubheading = classnames(
  'text-3xl',
  'font-extrabold',
  'md:text-4xl',
  'text-nzxt-light-grey-600',
  '2xl:max-w-3xl',
  'max-w-xl'
);
const defaultSubheading = classnames('md:text-xl', 'max-w-3xl');
export const signUpSubheading = (
  isInverted: boolean,
  isSimple: boolean
): string =>
  classnames('my-3', 'mx-auto', 'lg:mx-0', 'md:my-4', {
    [darkBgText]: isInverted && !isSimple,
    [lightBgTextSubeader]: !isInverted && !isSimple,
    [simpleDarkSubheading]: isSimple && isInverted,
    [simpleLightSubheading]: isSimple && !isInverted,
    [defaultSubheading]: !isSimple,
  });

export const signUpFormWrapper = (isSimple: boolean): string =>
  classnames('lg:flex-1', 'relative', {
    [defaultSpacing]: !isSimple,
    [simpleSpacing]: isSimple,
  });

export const signUpFormButton = classnames(
  'lg:ml-3',
  'w-full',
  'md:w-auto',
  'shrink-0'
);
export const signUpLegalDiscolure = (isInverted: boolean): string =>
  classnames('text-xxs', 'leading-tight', {
    [darkBgText]: isInverted,
    [lightBgTextLegal]: !isInverted,
  });

export const signUpLegalDiscolureWrapper = classnames('mt-2');
export const signUpLegalLink = classnames('font-medium', 'text-xxs');
const formLoading = classnames('opacity-50');
const formNotLoading = classnames('opacity-100');
export const signUpForm = (isLoading: boolean): string =>
  classnames(
    { [formLoading]: isLoading, [formNotLoading]: !isLoading },
    'sm:flex',
    'flex-col',
    'md:flex-row',
    'space-y-2',
    'md:space-y-0',
    'md:space-x-2',
    'items-start'
  );

const defaultStyles = classnames(
  'mx-auto',
  'flex',
  'flex-col',
  'lg:flex-row',
  'items-center',
  'py-2'
);
const simpleStackStyles = classnames(
  'max-w-7xl',
  'mx-auto',
  'px-4',
  'sm:px-6',
  'lg:px-8'
);
export const extendedWrapper = (isSimple: boolean): string =>
  classnames('lg:relative', {
    [simpleStackStyles]: isSimple,
    [defaultStyles]: !isSimple,
  });

const darkBg = classnames(
  'bg-gradient-to-b',
  'from-nzxt-dark-grey-600',
  'via-nzxt-dark-grey-700',
  'to-nzxt-dark-grey-800',
  'overflow-hidden'
);
const lightBg = classnames('bg-nzxt-light-grey-100', 'overflow-hidden');
const defaultBg = classnames('bg-transparent', 'overflow-hidden');

export const backgroundStyles = {
  transparent: defaultBg,
  light: lightBg,
  dark: darkBg,
};

export const imageWrapper = classnames(
  'lg:max-w-screen-2xl',
  'w-full',
  'h-full'
);

export const imageContainer = classnames(
  'lg:absolute',
  'lg:right-0',
  'lg:top-1/2',
  'lg:transform',
  'lg:-translate-y-1/2',
  'mb-6',
  'lg:mb-0',
  'max-w-md',
  'md:max-w-xl',
  'mx-auto',
  'lg:mx-0'
);
