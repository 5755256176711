/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'clsx';
import { setUserEmailCookie } from '@utils/user-email-cookie';
import { ROUTE_LEGAL } from '@constants';
import { useTranslation as t } from '@utils/hooks';
import type { SignUpFormData, DatoProductImage } from '@framework/api/types';
import { useRouter } from 'next/router';
import ButtonLink from '@components/ButtonLink';
import Image from '@components/Image';
import Input from '@components/Input';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import Button from '@components/Button';
import CheckboxInput from '@components/CheckboxInput';

import extraStyles from './extraStyles.module.css';
import * as styles from './styles';

type Props = {
  heading?: string;
  subheading?: string;
  background?: 'transparent' | 'light' | 'dark';
  simpleStacked?: boolean;
  emailListId?: string;
  backgroundImage?: DatoProductImage;
  image?: DatoProductImage;
  asCard?: boolean;
};

const SignUpSection: FC<Props> = ({
  heading,
  subheading,
  background,
  simpleStacked = false,
  emailListId,
  backgroundImage,
  image,
  asCard = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  // TODO: Why don't the following types work?
  //   * KeyboardEvent<HTMLButtonElement>
  //   * KeyboardEvent
  // Circle back on this

  const router = useRouter();
  const { locale } = router;
  const { register, handleSubmit, formState, reset } = useForm<SignUpFormData>({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
  });

  const { errors } = formState;

  const resetForm = (e): void => {
    e.preventDefault();
    reset();
    setError(false);
    setSuccess(false);
    setLoading(false);
  };

  const onSubmit = async ({ email }): Promise<void> => {
    try {
      setLoading(true);
      setError(false);

      fetch('/api/klaviyo', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          listId: emailListId,
          locale,
          action: 'joinNewsletter',
        }),
      })
        .then(res => res.json())
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(res => {
          if (res?.errors?.length > 0) {
            setLoading(false);
            setError(true);
            setSuccess(false);
          } else {
            setLoading(false);
            setSuccess(true);
            setUserEmailCookie(email);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(err => {
          setLoading(false);
          setError(true);
          setSuccess(false);
        });
    } catch (err) {
      setLoading(false);
      setError(true);
      setSuccess(false);
    }
  };

  const SUCCESS_MESSAGE = t('cart_heading_success');
  const ERROR_MESSAGE = t('cart_heading_error');
  const isInverted = background === 'dark';
  const HEADING_DEFAULT = t('email_stay_in_touch');
  const SUB_HEADING_DEFAULT = t('email_sub_copy');

  const backgroundStyles = backgroundImage?.responsiveImage?.src && {
    backgroundImage: `url(${backgroundImage?.responsiveImage?.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center left',
    backgroundSize: 'cover',
  };

  const composedClassNameWrapper = useMemo(
    () => cn(asCard ? null : styles.backgroundStyles[background]),
    [background, asCard]
  );

  const composedClassNameInnerWrapper = useMemo(
    () =>
      asCard
        ? cn(
            styles.backgroundStyles[background],
            styles.extendedWrapper(simpleStacked),
            styles.cardWrapper
          )
        : styles.extendedWrapper(simpleStacked),
    [background, asCard, simpleStacked]
  );

  return (
    <SectionWrapper
      className={composedClassNameWrapper}
      style={backgroundStyles}
    >
      <ContentWrapper
        data-test-id="email-signup-block"
        className={composedClassNameInnerWrapper}
      >
        {simpleStacked && image && (
          <div
            data-test-id="email-signup-image-block"
            className={styles.imageContainer}
          >
            <Image
              data={image.responsiveImage}
              width={image.responsiveImage.width}
              height={image.responsiveImage.height}
            />
          </div>
        )}
        <div className={styles.signUpHeadingWrapper(simpleStacked)}>
          <h2
            data-test-id="email-signup-heading"
            className={cn(styles.signUpheading(isInverted), {
              [extraStyles.glowDark]: isInverted,
            })}
          >
            {heading || HEADING_DEFAULT}
          </h2>
          <p
            data-test-id="email-signup-subheading"
            className={cn(styles.signUpSubheading(isInverted, simpleStacked), {
              [extraStyles.glowDark]: isInverted,
            })}
          >
            {subheading || SUB_HEADING_DEFAULT}
          </p>
        </div>
        <div className={styles.signUpFormWrapper(simpleStacked)}>
          <form
            data-test-id="email-signup-form"
            className={styles.signUpForm(loading)}
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!success && !error && (
              <>
                <Input
                  data-test-id="email-signup-email-notify-block"
                  type="email"
                  autoComplete="email"
                  inputLabel={t('label_email_address')}
                  labelFor="emailAddress"
                  classes={{
                    inputClass: 'w-full h-11',
                    labelClass: 'w-full flex',
                  }}
                  srOnlyLabel
                  {...register('email', {
                    required: true,
                    pattern:
                      // eslint-disable-next-line
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  requiredError={
                    errors.email && errors.email.type === 'required'
                  }
                  patternError={
                    errors.email &&
                    errors.email.type === 'pattern' &&
                    t('invalid_email')
                  }
                />
                <Button
                  dataTestId="email-signup-submit"
                  type="submit"
                  buttonStyle="primary"
                  dark={isInverted}
                  disabled={!termsChecked}
                  className={styles.signUpFormButton}
                >
                  {t('email_notify')}
                </Button>
              </>
            )}
            {success && (
              <div className={styles.signUpForm(false)}>
                <h3
                  className={styles.signUpheading(isInverted)}
                  data-test-id="email-signup-success-message"
                >
                  {SUCCESS_MESSAGE}
                </h3>
              </div>
            )}
            {error && (
              <div className={styles.signUpForm(false)}>
                <Button
                  buttonStyle="secondary"
                  type="button"
                  onClick={e => resetForm(e)}
                  disabled={loading}
                >
                  {ERROR_MESSAGE}
                </Button>
              </div>
            )}
          </form>
          {!success && !error && (
            <div className={styles.signUpLegalDiscolureWrapper}>
              <CheckboxInput
                data-test-id="email-signup-legal-discolure-check"
                id="cart_tcs"
                name="cart_tcs"
                checked={termsChecked}
                onChange={() => setTermsChecked(!termsChecked)}
                labelFor="cart_tcs"
                checkBoxLabel={
                  <p className={styles.signUpLegalDiscolure(isInverted)}>
                    {t('email_legal')}
                    <ButtonLink
                      data-test-id="email-signup-privacy-policy-link"
                      href={`/${ROUTE_LEGAL}/privacy-policy`}
                      customMargin
                      className={styles.signUpLegalLink}
                      dark={isInverted}
                      noChevron
                      internalLink
                    >
                      {t('privacy_policy')}
                    </ButtonLink>{' '}
                    {t('and')}{' '}
                    <ButtonLink
                      data-test-id="email-signup-terms-of-service-link"
                      href={`/${ROUTE_LEGAL}/terms-of-service`}
                      customMargin
                      className={styles.signUpLegalLink}
                      dark={isInverted}
                      noChevron
                      internalLink
                    >
                      {t('terms_conditions')}
                    </ButtonLink>
                  </p>
                }
              />
            </div>
          )}
        </div>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default memo(SignUpSection);
