import { parseCookies } from 'nookies';
import { USER_EMAIL_COOKIE_NAME } from '@constants';

type GetUserEmailCookie = () => string | null;

const getUserEmailCookie: GetUserEmailCookie = () => {
  const cookies = parseCookies();

  return cookies[USER_EMAIL_COOKIE_NAME];
};

export default getUserEmailCookie;
